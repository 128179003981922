import React, {useEffect, useLayoutEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Form} from 'react-bootstrap';

const IsBitcoinRising = () => {

    const [gain24Hours, setGain24Hours] = useState("");
    const [btcCurrentPrice, setBtcCurrentPrice] = useState();
    const [isBtcRising, setIsBtcRising] = useState("");

    useEffect(() => {
        const bitcoinValueApiUrl = "https://api.pro.coinbase.com/products/BTC-USD/stats";
        fetch(bitcoinValueApiUrl)
            .then((response) => response.json())
            .then((data) => {
                setBtcCurrentPrice(data.last);
                setGain24Hours(((Number(data.last) - Number(data.open)) / Number(data.open) * 100).toFixed(2).toString());
            });


        if (gain24Hours > 0) {
            setIsBtcRising("YES")
        } else {
            setIsBtcRising("NO")
        }
    });

    return (
        <div className={Number(gain24Hours) > 0 ? "is-bitcoin-rising bg-success" : Number(gain24Hours) < 0 ? "is-bitcoin-rising bg-danger" : "is-bitcoin-rising"}>
            <Form className="ibr-form">
                <Form.Group>
                    <h1 className="ibr-h1">Is Bitcoin Rising?</h1>
                    <Form.Label className="ibr-form-label">{isBtcRising}</Form.Label>
                    <hr />
                    <h2 className="ibr-h2">Bitcoin Current Price</h2>
                    <Form.Label className="ibr-form-label">{btcCurrentPrice}</Form.Label>
                    <hr />
                    <h2 className="ibr-h2">Bitcoin 24h %</h2>
                    <Form.Label className="ibr-form-label">{gain24Hours > 0 ? "+" + gain24Hours + "%" : gain24Hours + "%"}</Form.Label>
                    <hr />
                    <Form.Label className="ibr-form-label-desc text-center">Values are taken from Coinbase Pro.</Form.Label>
                    <Form.Label className="ibr-form-label-desc text-center">Please check values before investing:</Form.Label>
                    <Form.Label className="ibr-form-label-desc text-center"><a href="https://pro.coinbase.com/trade/BTC-USD">Coinbase Pro</a></Form.Label>

                </Form.Group>
            </Form>
        </div>
    );
}

export default IsBitcoinRising;
