import React from "react";
import './App.css';
import IsBitcoinRising from "./components/IsBitcoinRising";

const App = () => {
    return (
        <IsBitcoinRising></IsBitcoinRising>
    );
}

export default App;
